import { 
    START_PAGE,
    FIRM_PAGE,
    TIP_PAGE,
    DEP_PAGE,
    PRI_PAGE,
    USR_PAGE
} from "./Consts";

import FormApp from "../pages/formApp";
import Applications from "../pages/applications";
import Firms from "../pages/firms";
import Tips from "../pages/tips";
import Depart from "../pages/depart";
import Priority from "../pages/priority";
import Usr from "../pages/usr";

export const authRoutes = [
    {
      path: START_PAGE,
      Component: Applications,
    },
    {
      path: FIRM_PAGE,
      Component: Firms,
    },
    {
      path: TIP_PAGE,
      Component: Tips,
    },
    {
      path: DEP_PAGE,
      Component: Depart,
    },
    {
      path: PRI_PAGE,
      Component: Priority,
    },
    {
      path: USR_PAGE,
      Component: Usr,
    },
];


export const guestRoutes = [
    {
      path: START_PAGE,
      Component: FormApp,
    },
  ];