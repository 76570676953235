import React, { useState } from 'react';
import { Container, Form ,Button} from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AutocompleteInput from '../components/AutoInput';
import AutoFromBX24 from '../components/AutoFromBX24';
import Fetching from '../components/Fetching';
const FormApp =()=> {

    // Состояние для управления значениями полей
  const [nameInput, setNameInput] = useState('');
  const [commentInput, setCommentInput] = useState('');
  const [departIdInput, setDepartIdInput] = useState('');
  const [departName, setDepartName] = useState('');
  const [firmIdInput, setFirmIdInput] = useState('');
  const [firmName, setFirmName] = useState('');
  const [priorityIdInput, setPriorityIdInput] = useState('');
  const [priorityName, setPriorityName] = useState('');
  const [tipIdInput, setTipIdInput] = useState('');
  const [tipIdName, setTipName] = useState('');
  const [usrIdInput, setUsrIdInput] = useState('');
  const [usrName,setUsrName] = useState('');
  const apiKey = process.env.REACT_APP_API_KEY;

  const handleButtonClick = async () => {
      const now = new Date();
      const currentDayOfWeek = now.getDay(); // 0 - воскресенье, 1 - понедельник, ..., 5 - пятница, 6 - суббота
      const currentHour = now.getHours();

      // Определение крайнего срока в зависимости от времени и дня недели
      let deadline;
      if (currentDayOfWeek === 5 && currentHour >= 15) {
        // Если пятница после 15:00, устанавливаем крайний срок на понедельник до 9:00
        const monday = new Date(now);
        monday.setDate(now.getDate() + (1 + 7 - currentDayOfWeek) % 7); // Переход к понедельнику
        deadline = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 9, 0, 0);
      } else {
        // В остальных случаях применяем логику как ранее
        if (currentHour < 15) {
          // Если до 15 часов, устанавливаем крайний срок на сегодня до 17 часов
          deadline = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0);
        } else {
          // Если после 15 часов, устанавливаем крайний срок на завтра до 9 часов
          const tomorrow = new Date(now);
          tomorrow.setDate(now.getDate() + 1);
          deadline = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 9, 0, 0);
        }
      }
      const table = `
        [TABLE]
        ${tipIdName    && tipIdName.trim()    !== '' ? `[TR][TD]Услуга: [/TD]                  [TD]${tipIdName}[/TD][/TR]`   : ''}
        ${departName   && departName.trim()   !== '' ? `[TR][TD]Линия тех.поддержки: [/TD]     [TD]${departName}[/TD][/TR]`  : ''}
        ${firmName     && firmName.trim()     !== '' ? `[TR][TD]Название компании: [/TD]       [TD]${firmName}[/TD][/TR]`    : ''}
        ${nameInput    && nameInput.trim()    !== '' ? `[TR][TD]Название заявки (кратко): [/TD][TD]${nameInput}[/TD][/TR]`   : ''}
        ${usrName      && usrName.trim()      !== '' ? `[TR][TD]ФИО: [/TD]                     [TD]${usrName}[/TD][/TR]`     : ''}
        ${priorityName && priorityName.trim() !== '' ? `[TR][TD]Приоритет: [/TD]               [TD]${priorityName}[/TD][/TR]`: ''}
        [TR][TD]Номер ПК: [/TD][TD]В разработке[/TD][/TR]
        [/TABLE]
      `;
      const taskData = {
        fields: {
          TITLE: `Заявка с новой формы ${firmName} ${nameInput}`,
          DESCRIPTION: table,
          CREATED_BY: 520,
          RESPONSIBLE_ID: usrIdInput,
          UF_CRM_TASK: [`CO_${firmIdInput}`],
          TAGS: tipIdName,
          GROUP_ID: departIdInput, // Замените GROUP_ID на идентификатор вашего проекта
          DEADLINE: deadline.toISOString(),
          SE_PARAMETER: [
            {
              CODE: 3, // Замените на конкретный код параметра
              VALUE: 'Y',
            },
          ],
            
        },
      };
      axios.post("https://yapomogu24.bitrix24.ru/rest/164/5nfrwf6ra7blkrtm/tasks.task.add.json", taskData)
  .then(response => {
    console.log(response.data.result.task.id);
    
    // Возвращаем результат для передачи в следующий блок then
    return axios.post(`${apiKey}/application/add`, {
      id: response.data.result.task.id,
      name_app: nameInput,
      comment: commentInput,
      departId: departIdInput,
      firmId: firmIdInput,
      priorityId: priorityIdInput,
      tipId: tipIdInput,
      usrId: usrIdInput,
    });
  })
  .then(response => {
    console.log(response);

    // Обработка response внутри этого блока
    if (response.status === 201) {
      console.log(response.data);
      toast.success('Заявка успешно отправлена.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setCommentInput('');
      setTipIdInput('');
      setDepartIdInput('');
      setFirmIdInput('');
      setFirmName('');
      setNameInput('');
      setPriorityIdInput('');
      setUsrIdInput('');
    }
  })
          const firmBody = {
            id:firmIdInput,
            name_firm:firmName,
          }
          Fetching.post(`${apiKey}/firm/update`,firmBody)
      };

    
    return(
        <div>
          <div className="App">
            <header className="App-header">
       
            </header>
            <div className="headerTitle">ИТ ОТДЕЛ</div>
            <div className="headerMiniTitle">Оставьте заявку одному из сотрудников нашей компании, указав свои контактные данные, а так же подробное описание заявки. !!!! ВАЖНО *Компания и Название Заявки (кратко) пример заполнения: "ООО РК не работает ПК"</div>
            <Container className="container-app">
              <div className="inputFieldfirst"></div>
              <div className="input">
                <AutocompleteInput  apiUrl={`${apiKey}/tip`} name = 'name_tip' placeholder="Тип заявки" setSelect={setTipIdInput} inputValue={tipIdInput} setName={setTipName}/>
              </div>
              <div className="input">
                <AutocompleteInput  apiUrl={`${apiKey}/depart`} name = 'name_dep' placeholder="*Отдел" setSelect={setDepartIdInput} inputValue={departIdInput} setName={setDepartName}/>
              </div>
              <div className="input">
              <AutoFromBX24   name = 'TITLE' placeholder="*Название компании" setSelect={setFirmIdInput} inputValue={firmIdInput} setName={setFirmName}/>
              </div>
              <div className="input">
                <Form.Control 
                className ={ nameInput !== '' ? 'inputField' : 'inputFieldEmpty'}
                type="text" 
                value={nameInput} 
                placeholder="Название заявки(кратко)"
                onChange={(e) =>setNameInput(e.target.value)}/>
                {nameInput !== '' ? <p></p>:<p style={{ color: 'rgb(211, 0, 0)', fontSize: '12px', margin: '0' }}>Обязательное поле</p>}
              </div>
              <div className="input">
                <AutocompleteInput  apiUrl={`${apiKey}/user`} name = 'name_usr' placeholder="*ФИО" setSelect={setUsrIdInput} inputValue={usrIdInput} setName={setUsrName}/>
              </div>
              <div className="input">
              <Form.Control 
              className="inputField"
              type="text" 
              value={commentInput} 
              placeholder="Заявка (подробно)"
              onChange={(e) =>setCommentInput(e.target.value)} />
              </div>
              <div className="input">
                <AutocompleteInput  apiUrl={`${apiKey}/priority`} name = 'name_pri' placeholder="*Приоритет" setSelect={setPriorityIdInput} inputValue={priorityIdInput} setName={setPriorityName}/>
              </div>

        

     
        <Button 
          className="button"
          onClick={handleButtonClick}
        >
          Отправить
        </Button>
      
    </Container>
    <ToastContainer />
    </div>
        </div>
    );
};

export default FormApp;