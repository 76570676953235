import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Fetching from '../components/Fetching';
import Modal from '../components/Modal';
import { Button, Container, Form } from "react-bootstrap";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Usr =()=> {
    
    const [modalActiveAdd, setModalActiveAdd] = useState(false);
    const [name_usr, setName_usr] = useState("");
    const [login, setLogin] = useState("");
    const [password,setPassword]=useState("");
    const [grid, setGrid] = useState([]);
    const apiKey = process.env.REACT_APP_API_KEY;
    const [url, setUrl] = useState(`${apiKey}/user/admin`);



    const add = async () => {
        const req = {
            name_usr: name_usr,
            login: login,
            password:password,
          }
        const err = Fetching.post(url+'/add',req,'Пользователь создан.')
        if (err ===undefined){
            setName_usr('')
            setLogin('')
            setPassword('')
        }     
      };



    useEffect(() => {
        const fetchData = async () => {
            await Fetching.get(url, setGrid);
        };
        fetchData();
    }, [url, add]);

    
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortBy, setSortBy] = useState('id');
  
    const handleSort = (column) => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        setSortBy(column);
    };
  
    const sortedGrid = grid
        ? [...grid].sort((a, b) => {
            const order = sortOrder === 'asc' ? 1 : -1;
            return a[sortBy] > b[sortBy] ? order : -order;
        })
    : [];
    
    

    return(
        <div>
            <Modal active={modalActiveAdd} setActive={setModalActiveAdd}>
                <Container style={{ width: "500px" }}>
                    <h1 > Добавить </h1>
                    <Form >
                        <Form.Group >
                            <Form.Label>ФИО</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Введите текст"
                            value={name_usr}
                            onChange={(e) => setName_usr(e.target.value)}
                            required
                            as="textarea" rows={3}
                            />
                            <Form.Control.Feedback type="invalid">
                                Заполните обязательное поле!
                            </Form.Control.Feedback>
                            <span className="required">*</span>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Логин</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Введите текст"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            required
                            as="textarea" rows={1}
                            />
                            <Form.Control.Feedback type="invalid">
                                Заполните обязательное поле!
                            </Form.Control.Feedback>
                            <span className="required">*</span>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Введите текст"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            as="textarea" rows={1}
                            />
                            <Form.Control.Feedback type="invalid">
                                Заполните обязательное поле!
                            </Form.Control.Feedback>
                            <span className="required">*</span>
                        </Form.Group>

                        <Form.Group>
                        <Button 
                        className="button-func"
                        onClick={() => {
                            add();
                        }}
                        >
                            Добавить
                        </Button>
                        
                        <Button
                        className="button-func"
                        onClick={() => {
                            setModalActiveAdd(false);
                        }}
                        >
                            Закрыть
                        </Button>
                    </Form.Group>
                    </Form>
                </Container>
            </Modal>
        <div className='grid-container' >
            <Table  bordered hover>
                <thead style={{ position: 'sticky', top: '0' }}>
                    <tr>
                        <th onClick={() => handleSort('id')}>id</th>
                        <th onClick={() => handleSort('name_tip')}>ФИО</th>
                        <th onClick={() => handleSort('login')}>Логин</th>
                        <th onClick={() => handleSort('password')}>Пароль</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedGrid.map((item, index) => (
                        <tr
                        key={index}
                        onClick={() => {
                        console.log(item);
                        }}
                        >
                            <td>{item.id}</td>
                            <td> {item.name_usr}  </td>
                            <td> {item.login}  </td>
                            <td> {item.password}  </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            
        </div>

        <button
        className="button-func"
        onClick={(event) => {
        setModalActiveAdd(true);
        setName_usr("");
        setLogin("");
        setPassword("");
        }}
        >
        Добавить
        </button>
        
        <ToastContainer />
        </div>
    )
}
export default Usr;