import axios from 'axios';
import { toast } from 'react-toastify';

export default class Fetching {
  static fetchTasks(taskData) {
    try {
      const response = axios.post(
        `https://yapomogu24.bitrix24.ru/rest/tasks.task.add.json`,
        {
          
          auth: '2CW44VII2GxAO2Sev0ss1EQDA2AsHbJKu4ZhG6dg32TMQw62Fj',
          data: taskData,
        }
      );
  
      // Обработка успешного создания задачи
      console.log('Task created successfully:', response.data);
      return response.data;
    } catch (error) {
      // Обработка ошибок
      console.error('Error creating task:', error);
      throw error;
    }
  };

  static fetchClients (setSelect, input){
    try {
      axios.get(
        'https://yapomogu24.bitrix24.ru/rest/164/5nfrwf6ra7blkrtm/crm.company.list.json',
        {
          params: {
              filter: {
                  "%TITLE": input
              },
              select: ['TITLE']
          }
      }
        
      ).then(response => {
        setSelect(response.data.result);
      })
    } catch (error) {
      console.error('Ошибка при получении списка клиентов из Битрикс24:', error);
    }
  };
  /*
  static async fetchClients (setSelect){
    const batchSize = 50; // Количество записей в каждом запросе
    let start = 0;
    let allData = [];
  
    try {
      // Первый запрос для получения общего количества записей
      const totalResponse = await axios.get(
        'https://yapomogu24.bitrix24.ru/rest/164/5nfrwf6ra7blkrtm/crm.company.list.json',
        {
          params: {
            start: 0,
            select: ['TITLE'], // Порядок сортировки по ID, можно изменить
          },
        }
      );
  
      const totalItems = totalResponse.data.total;
  
      // Запросы для получения всех записей
      while (start < totalItems) {
        const response = await axios.get(
          'https://yapomogu24.bitrix24.ru/rest/164/5nfrwf6ra7blkrtm/crm.company.list.json',
          {
            params: {
              start,
              select: ['TITLE'], // Порядок сортировки по ID, можно изменить
            },
          }
        );
  
        allData = allData.concat(response.data.result);
        start += batchSize;
      }
  
      console.log(allData);
      setSelect(allData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };*/

    static get(url,setItems){
        axios.get(url)
        .then(response => {
          if (response.status === 200) {
            setItems(response.data)
          }
          
        })
        .catch(errors => {
          if (errors.response.status === 500){
            setItems(errors.response.data)
          }
        });
    }
    static post(url,body){
        axios.post(url,body)
        .then(response => {
            console.log(response)
            return response
          })
          .catch(errors => {
            if (errors.response.status === 500){
              toast.error('Ошибка при отправке.', {
                position: "top-right",
                autoClose: 5000, // Уведомление исчезнет через 5 секунд
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            console.log( errors.response.data)
            return errors.response
          });
    }
}