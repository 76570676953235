import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import 'react-toastify/dist/ReactToastify.css';
import Fetching from '../components/Fetching';
import { Accordion } from 'react-bootstrap';
import ExelDownload from '../components/ExelDownload';

const Applications =()=> {

    const [applicationsGrid, setApplicationsGrid] = useState([]);
    const apiKey = process.env.REACT_APP_API_KEY;
    const [url, setUrl] = useState(`${apiKey}/application`);
      useEffect(() => {
        Fetching.get(url,setApplicationsGrid);
      }, [url]);

    
    const SortByItem = (key,value) => {
      setUrl(`${apiKey}/application/sort?tableName=${key}&id=${value}`);
    };
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortBy, setSortBy] = useState('id');


    const handleSort = (column) => {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      setSortBy(column);
    };

    const sortedApplicationsGrid = applicationsGrid
      ? [...applicationsGrid].sort((a, b) => {
        const order = sortOrder === 'asc' ? 1 : -1;
        return a[sortBy] > b[sortBy] ? order : -order;
      })
    : [];

    return(

      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion Item #1</Accordion.Header>
        <Accordion.Body>
        <div className='grid-container'>
          <Table  bordered hover>
          <thead style={{ position: 'sticky', top: '0' }}>
          <tr>
            <th onClick={() => handleSort('id')}>id</th>
            <th onClick={() => handleSort('name_app')}>Название</th>
            <th onClick={() => handleSort('name_usr')}>Ответственный</th>
            <th onClick={() => handleSort('status')}>Статус</th>
            <th onClick={() => handleSort('name_tip')}>Тип задачи</th>
            <th onClick={() => handleSort('name_firm')}>Имя компании</th>
            <th onClick={() => handleSort('name_dep')}>Отдел</th>
            <th onClick={() => handleSort('name_pri')}>Приоритет</th>
          </tr>
        </thead>
        <tbody>
          {sortedApplicationsGrid.map((item, index) => (
            <tr
              key={index}
              onClick={() => {
                console.log(item);
              }}
            >
              
              <td>{item.id}</td>
              <td onClick={() => SortByItem('name_app', item.name_app)}>  {item.name_app}          </td>
              <td onClick={() => SortByItem('usrId',    item.usrId)}>      {item.usr.name_usr}     </td>
              <td onClick={() => SortByItem('satus',    'Открыта')}>      {item.status}            </td>
              <td onClick={() => SortByItem('tipId',    item.tipId)}>     {item.tip.name_tip}      </td>
              <td onClick={() => SortByItem('firmId',   item.firmId)}>    {item.firm.name_firm}    </td>
              <td onClick={() => SortByItem('departId', item.departId)}>  {item.depart.name_dep}   </td>
              <td onClick={() => SortByItem('priorityId',item.priorityId)}>{item.priority.name_pri}</td>
            </tr>
          ))}
        </tbody>
            </Table>
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <ExelDownload dataFromDatabase = {applicationsGrid}/>
      </Accordion>
    )
}
export default Applications;