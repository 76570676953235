import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';

const AutocompleteInput = ({ apiUrl, name, placeholder ,setSelect,inputValue,setName}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching data from API:', error);
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const clear = async () => {
      if (inputValue ===''){
        setSelectedOption([]);
      }
      
    }

    clear();
  }, [inputValue]);

  const handleInputChange = (selected) => {
    setSelectedOption(selected);
    if (selected && selected.length > 0) {
      setSelect(selected[0].id);
      if (setName) {
        setName(selected[0][name])
        console.log(selected[0][name],selected[0].id)
      }
      
    } else {
      // Если selected отсутствует или его длина равна 0
      setSelectedOption([]);
      setSelect(''); // Или выполните другие действия в зависимости от ваших требований
      console.log('очистка')
    }
  };

  return (
    <div>
      <Typeahead
      inputProps={{className : inputValue !== '' ? 'inputField' : 'inputFieldEmpty'}}
      id="autocomplete-input"
      labelKey={name}
      options={options}
      placeholder={placeholder}
      selected={selectedOption}
      //maxResults= {1}
      onChange={handleInputChange}
      />
      {inputValue !== '' ? <p></p>:<p style={{ color: 'rgb(211, 0, 0)', fontSize: '12px', margin: '0' }}>Обязательное поле</p>}
    </div>
  );
};
export default AutocompleteInput