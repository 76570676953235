import React from "react";
import { BrowserRouter } from "react-router-dom";
import Approuter from "./components/Router";

function App() {

  

  return (
   <div>
    <BrowserRouter>
      <Approuter/>
    </BrowserRouter>
   </div>
  );
}

export default App;
