import React from 'react';
import * as XLSX from 'xlsx';

const ExelDownload = ({ dataFromDatabase }) => {
  const handleDownload = () => {
    // Преобразование данных в формат, подходящий для xlsx
    const dataForExcel = dataFromDatabase.map(item => ({
      // Модифицируйте это в соответствии с вашей структурой данных
      ID: item.id,
      Название: item.name_app,
      // Добавьте другие колонки по мере необходимости
    }));

    // Создание экземпляра книги
    const ws = XLSX.utils.json_to_sheet(dataForExcel);

    // Создание книги
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Генерация и скачивание файла
    XLSX.writeFile(wb, 'report.xlsx');
  };

  return (
    <div>
      {/* Ваш компонент */}
      <button onClick={handleDownload}>Скачать отчет</button>
    </div>
  );
};

export default ExelDownload;