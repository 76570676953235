// Header.jsx
import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const Header = () => {
  return (
    <Navbar  sticky="top" className="custom-navbar">
      <Container className ="container-heder">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav >
            <Nav.Link as={Link} to="/" className="nav-link">
              Заявки
            </Nav.Link>
            <Nav.Link as={Link} to="/tip" className="nav-link">
              Тип
            </Nav.Link>
            <Nav.Link as={Link} to="/firm" className="nav-link">
              Фирмы
            </Nav.Link>
            <Nav.Link as={Link} to="/user" className="nav-link">
              Сотрудники
            </Nav.Link>
            <Nav.Link as={Link} to="/priority" className="nav-link">
              Приоритет
            </Nav.Link>
            <Nav.Link as={Link} to="/depart" className="nav-link">
              Отдел
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
