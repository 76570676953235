import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { authRoutes, guestRoutes } from "../utils/Routes";
import Header from "./Header";
const Approuter = () => {

  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    user && setCurrentUser(user);
    console.log(currentUser);
  }, [currentUser]);
  return (
    <Routes>
     {currentUser === 'Admin' &&
        authRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<><Header/><Component /></>} exact />
        ))}
    {currentUser === 'Sotr' &&
        authRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} exact />
        ))}
      {guestRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} exact />
      ))}
    </Routes>
  );
};
export default Approuter;