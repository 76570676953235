import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Fetching from './Fetching';

const AutocompleteInput = ({ name, placeholder ,setSelect,inputValue,setName}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [filter,setFilter] = useState('')


  useEffect(() => {
    if (filter && filter.length >= 3) {
        Fetching.fetchClients(setOptions, filter);
      }
    if (!filter || filter.length < 3) {
        setOptions([])
      }
  }, [filter]);

  useEffect(() => {
    const clear = async () => {
      if (inputValue ===''){
        setSelectedOption([]);
      }
      
    }

    clear();
  }, [inputValue]);

  const handleInputChange = (selected) => {
    setSelectedOption(selected);
    if (selected && selected.length > 0) {
      setSelect(selected[0].ID);
      setName(selected[0].TITLE)
      console.log(selected[0].ID,selected[0].TITLE)
    } else {
      // Если selected отсутствует или его длина равна 0
      setSelectedOption([]);
      setSelect(''); // Или выполните другие действия в зависимости от ваших требований
      console.log('очистка')
    }
  };

  return (
    <div>
      <Typeahead
      inputProps={{className : inputValue !== '' ? 'inputField' : 'inputFieldEmpty'}}
      id="autocomplete-input"
      labelKey={name}
      options={options}
      placeholder={placeholder}
      selected={selectedOption}
      onInputChange={(input) => {setFilter(input)}}
      //maxResults= {1}
      onChange={handleInputChange }
      />
      {inputValue !== '' ? <p></p>:<p style={{ color: 'rgb(211, 0, 0)', fontSize: '12px', margin: '0' }}>Обязательное поле</p>}
    </div>
  );
};
export default AutocompleteInput